import { Account } from "./accounts"
import { User } from "./activity"
import { formatDate, logger } from "./utils"

export enum TimestampAction {
  RECEIVED, // When the call was received by BE side
  BROADCASTED,
  RINGING,
  ANSWER,
  HOLD,
  REQUEUE,
  TRANSFER_TO_CLIENT,
  TRANSFER_TO_CALLER,
  STOP_TRANSFER,
}

export interface CallTimestamp {
  action: TimestampAction,
  timestamp: number, // Date.now()
}

export enum CallStateState {
  UNANSWERED,
  ANSWERED,
  ONHOLD,
  TRANSFERRING, // Warm transferring - waiting for client to pick up
  WITH_CLIENT, // Warm transferred and talking with client
  WITH_CALLER, // Warm transferred and talking with caller
}

export const ActiveCall = [
  CallStateState.ANSWERED,
  CallStateState.TRANSFERRING,
  CallStateState.WITH_CALLER,
  CallStateState.WITH_CLIENT,
]

export const CanAnswer = [
  CallStateState.UNANSWERED,
  CallStateState.ONHOLD,
]

export const CanHold = [
  CallStateState.ANSWERED,
]

export const CanStopTransfer = [
  CallStateState.TRANSFERRING,
  CallStateState.WITH_CALLER,
  CallStateState.WITH_CLIENT,
]

export enum WarmTransferredTo {
  CLIENT = 'CLIENT',
  CALLER = 'CALLER'
}

export enum CallType {
  OUTBOUND = 'Outbound',
  INBOUND = 'Inbound',
  
  // Calls that are not controlled by us. Non transferable, non requeue-able.
  // Ex: VM Box, VM Box management, phone tree management.
  UNBOUND = 'Unbound',
}

export enum UnboundCallType {
  VOICEMAIL = 'Voicemail',
  VMBOX_MANAGEMENT = 'VM Box Management',
  PT_MANAGEMENT = 'Phone Tree Management',
}

export interface ClientKnownCaller {
  callerNumber: string,
  name: string,
  notes: string,
}

export interface CallRecord {
  id?: string | null,
  account?: Account,
  answeredBy?: string | null,
  answeredByStaff?: string | null,
  answeredByStaffExtension?: string | null,
  callerName?: string | null,
  callerNumber?: string | null,
  callRecordingLink?: string | null,
  callSid?: string | null,
  callTakenBy?: string | null,
  callTakenOn?: string | null,
  clientKnownCaller?: ClientKnownCaller | null,
  calleeCallRecordingLink?: string | null,
  completedFormIds?: string | null,
  direction?: string | null,
  durationInSeconds?: number | null,
  endTime?: string | null,
  firstInteraction?: string | null,
  flagEmailSent?: boolean | null,
  flagNotes?: string | null,
  hangedUpBy?: string | null,
  holdCount?: string | null,
  holdTime?: number | null,
  interactions?: string | null,
  notes?: string | null,
  outboundCallNumberDialed?: string | null,
  receptionistAbbyTeam?: string | null,
  requeueCount?: string | null,
  requeueTime?: number | null,
  ringTimeInSeconds?: number | null,
  screenPop?: string | null,
  startTime?: string | null,
  talkTimeInSeconds?: number | null,
  transferTimeInSeconds?: number | null,
  transferToName?: string | null,
  transferToNumber?: string | null,
}

export enum EmailSentOnCalls {
  All = 'All Calls',
  Live = 'LIVE Calls',
}

export interface CallState {
  accountId?: string,
  callRecordId?: string,
  name: string,
  conferenceName: string,
  callerNumber: string,
  state: CallStateState,
  timestamps: CallTimestamp[], // TODO: Convert into interactions
  conferenceJoinedBy?: string,
  requeued?: boolean,
  warmTransferredTo?: WarmTransferredTo,
  warmTransferredToNum?: string,
  isTest?: boolean, // For testing only, cannot answer etc
  createdAt: number,
  updatedAt?: number,
  isProcessing?: boolean // When waiting for BE response,
  type: CallType,
  hipaaCompliant?: boolean,
  commonMistakes?: boolean,
  spanishAccount?: boolean, // is spanishCalls enabled on account level
  spanishCalls?: boolean,
  spanishFlag?: boolean,
  emailSentOnCalls?: EmailSentOnCalls,
  tlc?: boolean,
  tlcPopup?: boolean,
  abbyStage2?: string,
  
  // This indicates if the conference has started or receptionist has joined and user can perform transfers
  transferEnabled?: boolean,

  // Indicates that caller has left the call and the call is only available for receptionist to wrap up warm transfer
  callerLeftWarmTransfer?: boolean,

  abbyTeamIds?: string[],
  block?: string,
  evoLevel?: string,
  requeueToOperatorId?: string,

  callerCallSid?: string,
  flagNotes?: FlagNote[],
  notes?: string,

  // Extensions under ? that has been dialed.
  // This is FE only and will be gone on refresh
  extensionsDialed?: string[],
  // When the call was last joined by current receptionist.
  // Disables hang up temporarily
  joinedAt?: number,
  // Transfer numbers that has been dialed
  // This is FE only and will be gone on refresh
  transfersDialed?: string[],

  // Call has been removed, but we will keep the information temporarily to avoid sync issue
  isRemoved?: boolean,
  removedAt?: number,
}

export type CallNoteState = Pick<CallState,
  'callerNumber' |
  'callRecordId' |
  'conferenceName' |
  'hipaaCompliant' |
  'name' |
  'notes'
> & {
  viewMode?: boolean,
}

export interface FlagNote {
  answeredBy: string,
  createdOn: string,
  note: string,
}

export interface CallSlot {
  name: string,
  userId: string,
  claimedBy?: string,
  // CCAA-1064 Add slight cooldown to each slot so that new calls does not immediately go there
  cooldownPeriod?: number,
  // Disable slot instead of removing it when it's removed from DOM
  disabled?: boolean,
}

interface FetchedCall {
  accountId: string,
  conferenceName: string,
  operatorCallSid: string,
  operatorId: string,
  requeued: string,
  status: string,
  speakingWithInWarmTransfer?: 'client' | 'customer',
  clientNumber?: string,
  type: string,
  emailSentOnCalls: EmailSentOnCalls,
  hipaaCompliant: string,
  commonMistakes: string,
  spanishAccount: string,
  spanishFlag: string,
  spanishCalls: string,
  tlc: string,
  tlcPopup: string,
  abbyStage2: string,
  name: string,
  callerCallSid: string,
  callerNumber: string,
  flagNotes: string, // JSON of FlagNote[]
  notes: string,
  interactions?: string,
  sfCallRecordId: string,
  abbyTeamIds?: string,
  block?: string,
  evoLevel?: string,
  requeueToOperatorId?: string,
  callerLeftWarmTransfer?: string,
  updatedAt?: number,
}

interface Interaction {
  Type: string,
  StartTime: string,
}

export const prepareFetchedCalls = (calls: FetchedCall[]): CallState[] => {
  const CALL_STATE_MAP: Record<string, number> = {
    OperatorConnecting: CallStateState.ANSWERED,
    Answered: CallStateState.ANSWERED,
    OnHold: CallStateState.ONHOLD,
    OutboundCalling: CallStateState.UNANSWERED,
    Requeued: CallStateState.UNANSWERED,
    WarmTransferring: CallStateState.TRANSFERRING,
    // Handled afterwards
    // WarmTransfer: CallStateState.WITH_CLIENT || CallStateState.WITH_CALLER,
  }

  const TIMESTAMPS_MAP: Record<string, number> = {
    Answered: TimestampAction.ANSWER,
    OnHold: TimestampAction.HOLD,
    OperatorConnecting: TimestampAction.ANSWER,
    OutboundCalling: TimestampAction.ANSWER,
  }

  const TRANSFER_MAP = {
    client: WarmTransferredTo.CLIENT,
    customer: WarmTransferredTo.CALLER,
  }


  const callStates = calls.map(call => {
    const timestamps = interactionsToTimestamps(call.interactions)
    const callState: CallState = {
      accountId: call.accountId,
      name: call.name,
      callerNumber: call.callerNumber,
      conferenceName: call.conferenceName,
      conferenceJoinedBy: call.operatorId,
      state: CALL_STATE_MAP[call.status],
      timestamps: timestamps?.length ? timestamps : [{
        action: TIMESTAMPS_MAP[call.status],
        timestamp: Date.now(),
      }],
      requeued: call.requeued === 'true',
      createdAt: Date.now(),
      updatedAt: call.updatedAt || Date.now(),
      warmTransferredTo: call.speakingWithInWarmTransfer ? TRANSFER_MAP[call.speakingWithInWarmTransfer] : undefined,
      warmTransferredToNum: call.clientNumber,
      type: call.type === 'Inbound' ? CallType.INBOUND : CallType.OUTBOUND,
      callerCallSid: call.callerCallSid,
      flagNotes: parseFlagNotes(call.flagNotes),
      notes: call.notes,
      transferEnabled: call.status === 'WarmTransfer' || call.status === 'Answered',
      callRecordId: call.sfCallRecordId,
      emailSentOnCalls: call.emailSentOnCalls,
      hipaaCompliant: call.hipaaCompliant === 'true',
      commonMistakes: call.commonMistakes === 'true',
      spanishAccount: call.spanishAccount === 'true',
      spanishFlag: call.spanishFlag === 'true',
      spanishCalls: call.spanishCalls === 'true',
      tlc: call.tlc === 'true',
      tlcPopup: call.tlcPopup === 'true',
      abbyStage2: call.abbyStage2,
      abbyTeamIds: call.abbyTeamIds?.split(','),
      block: call.block,
      evoLevel: call.evoLevel,
      requeueToOperatorId: call.requeueToOperatorId,

      callerLeftWarmTransfer: !!call.callerLeftWarmTransfer,
    }

    if (callState.warmTransferredTo) {
      callState.state = callState.warmTransferredTo === WarmTransferredTo.CLIENT ? CallStateState.WITH_CLIENT : CallStateState.WITH_CALLER
    }

    return callState
  })
  
  return callStates
}

export const interactionsToTimestamps = (interactions: string | undefined, callReceivedTime?: number): CallTimestamp[] | null => {
  if (!interactions) return null
  let interactionsArr: Interaction[]
  try {
    interactionsArr = JSON.parse(interactions)
  } catch (error) {
    logger('An error occured when parsing JSON string', interactions)
    return null
  }

  const TIMESTAMPS_ACTION_MAP = {
    CallInitiated: TimestampAction.RINGING,
    CallReinitiated: TimestampAction.RINGING,
    CallBroadcasted: TimestampAction.BROADCASTED,
    Answered: TimestampAction.ANSWER,
    Hold: TimestampAction.HOLD,
    Retrieved: TimestampAction.ANSWER,
    Requeued: TimestampAction.REQUEUE,
    TransferAttempted: TimestampAction.TRANSFER_TO_CLIENT,
    TransferCancelled: TimestampAction.STOP_TRANSFER,
    TransferCompleted: TimestampAction.STOP_TRANSFER,
  }

  const callTimestamps: CallTimestamp[] = []
  interactionsArr.slice().reverse().forEach(interaction => {
    const type = interaction.Type as keyof typeof TIMESTAMPS_ACTION_MAP
    if (TIMESTAMPS_ACTION_MAP[type] !== undefined) {
      callTimestamps.push({
        action: TIMESTAMPS_ACTION_MAP[type],
        timestamp: new Date(interaction.StartTime).getTime(),
      })
    }
  })

  if (callReceivedTime) {
    callTimestamps.push({
      action: TimestampAction.RECEIVED,
      timestamp: callReceivedTime,
    })
  }

  return callTimestamps
}

// Higher order can pick up calls from lower order
const EVO_ORDER = [
  '1',
  '2a',
  '2b',
  '2c',
  '2d',
  '3',
  '4',
  'FT',
]

export const userEvoLevelMatchCall = (userEvoLevelsStr: string, callEvoLevel: string) => {
  const evoLevel = callEvoLevel
    .replaceAll('Level', '')
    .replaceAll(' ', '')
    .replaceAll('-', '')

  if (evoLevel.toLowerCase() === 'all') return true

  const userEvoLevels = userEvoLevelsStr.split(';')
  let highestUserEvoLevelIndex = -1
  userEvoLevels.forEach(level => {
    const evoLevelIndex = EVO_ORDER.findIndex(e => e === level)
    if (evoLevelIndex > highestUserEvoLevelIndex) {
      highestUserEvoLevelIndex = evoLevelIndex
    }
  })

  const callEvoLevelIndex = EVO_ORDER.findIndex(e => e === evoLevel)

  return highestUserEvoLevelIndex >= callEvoLevelIndex
}

type Operator = Pick<User, 'id'|'teamId'|'userBlock'|'spanishCalls'|'teamName'> & {
  evoLevels: string | undefined
}

export const operatorCanPickUpCall = (user: Operator | undefined | null, call: CallState) => {
  const isForThisUser = call.requeueToOperatorId === user?.id

  const abbyTeamIdMatch = !call.abbyTeamIds ||
    user?.teamName?.toLowerCase() === 'all teams' ||
    call.abbyTeamIds.includes(user?.teamId || '')

  const blockMatch = !call.block ||
    user?.userBlock === '0' ||
    call.block === '0' ||
    call.block === user?.userBlock

  const isClaimableByUser = (
    (abbyTeamIdMatch || blockMatch) &&
    (!call.evoLevel || userEvoLevelMatchCall(user?.evoLevels || '', call.evoLevel)) &&
    (!call.spanishCalls || user?.spanishCalls) &&
    (!call.requeueToOperatorId || call.requeueToOperatorId === user?.id)
  )

  return isForThisUser || isClaimableByUser
}

export const getCallColors = (state: CallStateState = CallStateState.UNANSWERED) => {
  switch (state) {
    case CallStateState.ANSWERED:
      return ['#B4C6E7', '#18339F'];

    case CallStateState.ONHOLD:
      return ['#FFE699', '#213299'];

    case CallStateState.WITH_CALLER:
    case CallStateState.TRANSFERRING:
    case CallStateState.WITH_CLIENT:
      return ['#C6E0B4', '#213299'];

    default:
      return ['#FF9999', '#213299'];
  }
}

export const parseFlagNotes = (flagNotesRaw: string) => {
  let flagNotes: FlagNote[] = []
  try {
    flagNotes = JSON.parse(flagNotesRaw)
  } catch (err) { }

  return flagNotes
}

export const isActiveCall = (callState: CallState, userId?: string) => {
  // User is joining this call, let's consider it as active
  const isJoining = (callState?.isProcessing && callState?.state === CallStateState.UNANSWERED)
  const isCalling = (
    callState.conferenceJoinedBy === userId &&
    (
      ActiveCall.includes(callState.state) ||
      (callState.type === CallType.OUTBOUND && callState.state === CallStateState.UNANSWERED)
    )
  )

  return isJoining || isCalling
}

export interface FormData {
  id: string,
  sentTo: string[],
  subject: string,
  details: Record<string, string>,
  date: string,
}

export const prepareFormData = (rawFormData: Record<string, string>) => {
  const rawDetails = rawFormData.messageDetail.split('\n')
  const details: Record<string, string> = {}

  rawDetails.forEach(detail => {
    const splittedDetail = detail.split(': ')
    const key = splittedDetail.shift() as string
    const value = splittedDetail.join(': ')
    if (key && value) {
      details[key] = value
    }
  })

  const formData: FormData = {
    id: rawFormData.id,
    sentTo: rawFormData.sentTo?.split('\n')|| [],
    subject: rawFormData.subject,
    details,
    date: formatDate(rawFormData.messageSentUTC, 'MM/DD/YYYY hh:mm:ss A') || '',
  }

  return formData
}

export const isActiveCallTransferable = (activeCall?: CallState) => {
  if (!activeCall) {
    return false
  }

  return (
    activeCall.type !== CallType.UNBOUND &&
    activeCall.state !== CallStateState.TRANSFERRING &&
    !activeCall.isProcessing &&
    activeCall.transferEnabled &&
    !activeCall.warmTransferredTo
  )
}

// 3.5 seconds
export const CALL_TIMEOUT = process.env.REACT_APP_CALL_TIMEOUT_MS ? parseInt(process.env.REACT_APP_CALL_TIMEOUT_MS) : (3.5 * 1000)
