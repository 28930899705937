import React, { useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@mui/material";
import BackgroundLayout from "pages/BackgroundLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { nameToInitial } from "utils/utils";
import AnimatedButton from "components/buttons/AnimatedButton";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import { changeAvatar, deleteAvatar, getUserDetails } from "api/users";
import { addUserDetails } from "reducers/slices/userReducer";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { Close } from "@mui/icons-material";

const MyAccount = () => {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const [deleteAvatarOpen, setDeleteAvatarOpen] = useState<boolean>(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<string>('')
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAccount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId])

  const fetchAccount = () => {
    if (user?.userId) {
      getUserDetails(user.userId).then(res => {
        dispatch(addUserDetails(res.data))
      })
    }  
  }

  const onUploadClick = () => {
    if (fileInput.current) {
      fileInput.current.click()
    }
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 1024 * 1024) {
        dispatch(setSnackbar({ message: 'Maximum image size is 1MB', severity: SnackbarSeverity.ERROR }))
        return
      }

      const formData = new FormData()
      formData.append('file', file, file.name)

      setIsLoading('upload')
      changeAvatar(formData).then(res => {
        fetchAccount()
        dispatch(setSnackbar({ message: 'Avatar is successfully uploaded', severity: SnackbarSeverity.SUCCESS }))
      }).finally(() => {
        event.target.value = ''
        setIsLoading('')
      })
    }
  }

  const handleDeleteAvatar = () => {
    setDeleteAvatarOpen(false)
    setIsLoading('delete')
    deleteAvatar().then(res => {
      fetchAccount()
      dispatch(setSnackbar({ message: 'Avatar has been deleted', severity: SnackbarSeverity.SUCCESS }))
    }).finally(() => {
      setIsLoading('')
    })
  }

  return (
    <BackgroundLayout sx={{ height: 'calc(100% - 68px)' }}>
      <input
        style={{ display: 'none' }}
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={handleUpload}
      />

      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />

      <Dialog open={deleteAvatarOpen} onClose={() => setDeleteAvatarOpen(false)}>
        <DialogTitle>
          {'Delete Avatar'}
          <IconButton
            onClick={() => setDeleteAvatarOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#E80B0B',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pr: 6 }}>
          <Typography>Are you sure you want to delete your avatar?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAvatarOpen(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={handleDeleteAvatar}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Paper elevation={5}>
        <Box p={6} width={380} display="flex" flexDirection="column" alignItems="center">
          <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>My Account</Typography>
          <Avatar
            src={user?.details?.avatar}
            sx={{
              mt: 4,
              width: 128,
              height: 128,
              bgcolor: '#1033A5',
              fontSize: '72px',
            }}
          >
            {nameToInitial(user?.details?.name || '')}
          </Avatar>
          <Box mt={2} width="100%" display="flex" alignItems="center" justifyContent="center">
            <AnimatedButton
              label="Upload Avatar"
              sx={{
                mr: 1,
                width: 130,
                borderRadius: '5px',
              }}
              onClick={onUploadClick}
              loading={isLoading === 'upload'}
              disabled={!!isLoading}
            />
            <Button
              sx={{ width: 85 }}
              onClick={() => setDeleteAvatarOpen(true)}
              disabled={!!isLoading || !user?.details?.avatar}
            >
              Delete
            </Button>
          </Box>
          <Typography sx={{ mt: 3, fontSize: '28px', fontWeight: 600 }}>{user?.details?.name}</Typography>
          <Typography sx={{ mt: 1, fontSize: '16px', fontWeight: 600, color: '#1033A5' }}>{user?.details?.email}</Typography>
          <AnimatedButton
            label="Change Password"
            onClick={() => setChangePasswordOpen(true)}
            sx={{
              my: 4,
              width: 350,
              height: 40,
              fontSize: '16px',
            }}
            disabled={!!isLoading}
          />
        </Box>
      </Paper>
    </BackgroundLayout>
  );
};

export default MyAccount;
