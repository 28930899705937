import Main from "../pages/main/Main";
import { RootState } from "../reducers/store";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import QA from "../pages/main/QA";
import ReceptionistView from "pages/main/receptionist/ReceptionistView";
import ActivityView from "pages/main/activity/ActivityView";
import Loading from "pages/Loading";
import AuthRoutes from "./AuthRoutes";
import MyAccount from "pages/user/MyAccount";
import FormSubmitted from "abbyForms/FormSubmitted";
import PublicForm from "abbyForms/PublicFormWrapper";
import useCanReceiveCall from "hooks/useCanReceiveCall";

export const AppRoutes = () => {
  useCanReceiveCall();
  const user = useSelector((state: RootState) => state.user.user);
  const twilioLoading = useSelector((state: RootState) => state.ui.twilioLoading);

  if (!user)
    return (
      <AuthRoutes />
    );

  if (user?.customClaims?.role === 'QA') {
    return (
      <Routes>
        <Route path="main" element={<QA />} />
        <Route path="*" element={<Navigate to={{ pathname: "/main" }} />} />
      </Routes>
    )
  }

  if (twilioLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Routes>
      <Route path="/submitted" element={<FormSubmitted />} />
      <Route path="/form/:formId" element={<PublicForm />} />
      <Route path="/form/*" element={<PublicForm />} />

      <Route path="/*" element={<Main />}>
        <Route path="receptionist" element={<ReceptionistView />}></Route>
        <Route path="activity" element={<ActivityView />}></Route>
        <Route path="my-account" element={<MyAccount />}></Route>
        <Route path="*" element={<Navigate to={{ pathname: "/receptionist" }} />} />
      </Route>

      <Route path="*" element={<Navigate to={{ pathname: "/receptionist" }} />} />
    </Routes>
  );
};
