import moment from 'moment-timezone'
import agent, { ccAgent } from './agent'
import { removeLeadingPlus } from 'utils/utils'

export function getCallRecord(callRecordId: string) {
  return agent.get('/callRecords/' + callRecordId)
}

export function updateCallNotes(conferenceName: string, callRecordId: string, notes: string) {
  return ccAgent.patch('/callRecords/' + callRecordId, {
    conferenceName,
    notes,
  })
}

export function flagCall(conferenceName: string, callRecordId: string, flagNotes: string) {
  return ccAgent.patch(`/callRecords/${callRecordId}/flag`, {
    conferenceName,
    flagNotes
  })
}

export interface CallRecordsParams {
  callTypes?: string[],
  accountIds?: string[],
  block?: string[],
  teamIds?: string[],
  receptionistIds?: string[],
  direction?: string[],

  searchString?: string,
  callerNumber?: string,
  notes?: string,
  transferToNumber?: string,
  numberCallerCalled?: string,
  callSid?: string,

  from?: string,
  to?: string,

  limit: number,
  page: number,
}

export function getCallRecords(params: CallRecordsParams) {
  const isAllTeams = params.teamIds?.includes('all')
  return agent.get('/callrecords', { params: {
    searchString: removeLeadingPlus(params.searchString),
    callerNumber: removeLeadingPlus(params.callerNumber),
    notes: params.notes,
    transferToNumber: removeLeadingPlus(params.transferToNumber),
    numberCallerCalled: removeLeadingPlus(params.numberCallerCalled),
    callSid: params.callSid,

    callTypes: params.callTypes?.join(',') || undefined,
    accountIds: params.accountIds?.join(',') || undefined,
    block: params.block?.join(',') || undefined,
    teamIds: isAllTeams
      ? undefined
      : (params.teamIds?.join(',') || undefined),
    receptionistIds: params.receptionistIds?.join(',') || undefined,
    direction: params.direction?.length === 1 ? params.direction[0] : undefined,

    from: params.from,
    to: params.to,

    limit: params.limit,
    page: params.page,
  }})
}

export function getCallStats() {
  const date = moment().tz('US/Pacific').format('YYYY-MM-DD')
  return agent.get('/callrecords/stats/' + date)
}

// Not exactly callrecords but these are used for filters on call history
export function getBlocks() {
  return agent.get('/blocks')
}

export function getTeams() {
  return agent.get('/teams')
}

export function getFormData(id: string) {
  return agent.get('/receptionist-messages/' + id)
}