import { Box, Button, Collapse, Skeleton, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography, styled } from '@mui/material';
import { ArrowRightAlt, CallMade, CallMissed, CallReceived, Chat, Flag, KeyboardArrowDown, KeyboardArrowUp, PhoneCallback, TouchApp } from '@mui/icons-material';
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CallRecord, FormData, parseFlagNotes, prepareFormData } from 'utils/calls';
import { formatDate, logger, secondsToHoursMinuteSecond, secondsToMinuteSecond, ViewRoute } from 'utils/utils';
import { getCallRecord, getFormData } from 'api/callrecords';
import { setOpenAccountId } from 'reducers/slices/accountPanelReducer';
import { FormLink, getAccountAttributes, getAccountFormLinks } from 'utils/accounts';
import useAudioPlayer from 'hooks/useAudioPlayer';
import RecordingPlayer from './RecordingPlayer';
import CopyButton from 'components/buttons/CopyButton';
import { startCase } from 'lodash';
import FlagNotes from '../callCard/FlagNotes';
import { handleCallRecordLink } from 'reducers/thunks/accountThunk';
import { AppThunkDispatch } from 'reducers/types';
import { useAppDispatch, useAppSelector } from 'reducers/hooks';
import RecordingPlayerSmall from './RecordingPlayerSmall';
import AudioRecordingPlayer from './AudioRecordingPlayer';
import { useNavigate } from 'react-router-dom';

interface CalleeRecordingLink {
  link: string,
  number?: string,
}

interface Interactions {
  Type: string,
  Description: string,
  StartTime: string,
}

const linkTextStyle = {
  marginLeft: '4px',
  color: '#1033A5',
  fontWeight: 600,
  fontSize: '14px',
}

const linkStyle = {
  ...linkTextStyle,
  width: 'fit-content',
  fontSize: '12px',
  marginLeft: '0px',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}

const FormField = styled(Typography)(() => ({
  width: 450 - 16,
  fontWeight: 600,
  fontSize: '12px',
  padding: '4px 8px',
  backgroundColor: '#E4ECFC',
  border: '1px solid #CBD8F1',
  borderRadius: '5px',
}))

const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A'

const combineNameAndNumber = (name?: string | null, number?: string | null) => {
  if (name && number) {
    return `${name} (${number})`
  }
  if (name) return name
  return number
}

const getAnsweredBy = (record: CallRecord) => {
  const labels = []
  const { direction, answeredBy, answeredByStaff, answeredByStaffExtension, receptionistAbbyTeam } = record
  if (direction === 'Incoming') {
    if (answeredByStaff) labels.push(answeredByStaff)
    if (answeredByStaffExtension) labels.push('ext ' + answeredByStaffExtension)
    if (receptionistAbbyTeam) labels.push(receptionistAbbyTeam)
  } else {
    labels.push(answeredBy || 'Anonymous')
  }

  return labels.join(' - ')
}

interface IProps {
  callRecord: CallRecord,
  isGlobal?: boolean,
  setCallHistoryOpen?: (isOpen: boolean) => void,
}

export const HistoryCard: FC<IProps> = ({ callRecord, setCallHistoryOpen, isGlobal }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingForms, setIsLoadingForms] = useState<number>(0)
  const [fullRecord, setFullRecord] = useState<CallRecord | null>(null)
  const [formDatas, setFormDatas] = useState<FormData[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isInteractionsExpanded, setIsInteractionsExpanded] = useState<boolean>(false)
  const [isFormExpanded, setIsFormExpanded] = useState<Record<string, boolean>>({})
  const [isLinksOverflown, setIsLinksOverflown] = useState<boolean>(false)
  const cardRef = useRef<HTMLDivElement | null>(null)
  const audioPlayer = useAudioPlayer(callRecord?.callRecordingLink)
  const isViewingGlobalCallHistory = useAppSelector(state => state.callHistory.isViewingGlobalCallHistory)
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch
  const navigate = useNavigate()

  useEffect(() => {
    if (isExpanded && !fullRecord && callRecord?.id) {
      setIsLoading(true)
      getCallRecord(callRecord.id).then(res => {
        setFullRecord(res.data)
        const completedFormIds = res.data.completedFormIds
        if (completedFormIds) {
          const formIds = JSON.parse(completedFormIds)
          const promises = formIds.map(getFormData)
          setIsLoadingForms(formIds.length)

          Promise.all(promises).then((res) => {
            setFormDatas(res.map(e => prepareFormData(e.data)))
          }).finally(() => setIsLoadingForms(0))
        }
      }).finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded])

  useEffect(() => {
    if ((isGlobal && !isViewingGlobalCallHistory) || // On global but hide global history
      (!isGlobal && isViewingGlobalCallHistory) // On account history but opening global history
    ) {
      if (audioPlayer.isPlaying) {
        audioPlayer.pause()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewingGlobalCallHistory, isGlobal])

  useLayoutEffect(() => {
    const formLinks = document.getElementById('formLinks' + callRecord.id)
    if (!formLinks) return

    setIsLinksOverflown(formLinks.scrollHeight > formLinks.clientHeight)
  }, [callRecord])

  const collapseCard = () => {
    setIsExpanded(false)
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const record = useMemo(() => {
    return fullRecord || callRecord
  }, [fullRecord, callRecord])

  const interactions: Interactions[] = useMemo(() => {
    if (!fullRecord?.interactions) return []

    try {
      const interactions = JSON.parse(fullRecord.interactions)
      return interactions
    } catch (err) {
      logger('An error occured when parsing JSON string', fullRecord.interactions)
    }

  }, [fullRecord])

  const links = useMemo(() => {
    if (callRecord?.account) {
      return getAccountFormLinks(callRecord.account?.hostedsuiteLinks || '')
    }
    return []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRecord.account?.hostedsuiteLinks])


  const Info = (props: { label: string, value?: string | null }) => (
    <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>{`${props.label}: `}
      <Typography component="span" style={{ fontWeight: 600, fontSize: '12px' }}>
        {isLoading ? <Skeleton sx={{ display: 'inline-block' }} width={50} /> : (props.value || 'N/A')}
      </Typography>
    </Typography>
  )

  const openAccount = () => {
    if (record?.account?.id && setCallHistoryOpen) {
      navigate(ViewRoute.RECEPTIONIST_VIEW)
      setCallHistoryOpen(false)
      dispatch(setOpenAccountId(record.account.id))
    }
  }

  const handleLink = (link: FormLink) => {
    thunkDispatch(handleCallRecordLink(link, callRecord))
    if (isGlobal && setCallHistoryOpen) {
      navigate(ViewRoute.RECEPTIONIST_VIEW)
      setCallHistoryOpen(false)
    }
  }

  const calleeRecordingLinks = useMemo(() => {
    if (!callRecord?.calleeCallRecordingLink) return []

    const recordingLinks = callRecord.calleeCallRecordingLink.split(';')
    const transferInteractions = interactions.filter(e => e.Type === 'TransferAttempted')

    const links: CalleeRecordingLink[] = []

    recordingLinks.forEach((link, index) => {
      const interaction = transferInteractions[index]
      links.push({
        link,
        number: interaction?.Description || ''
      })
    })

    return links
  }, [callRecord?.calleeCallRecordingLink, interactions])

  return (
    <Box
      sx={{
        p: 2, mb: 1,
        '&:last-child': { mb: 0 },
        width: 'calc(100% - 32px)',
        borderRadius: '5px',
        backgroundColor: '#F5F8FD',
      }}
      ref={cardRef}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" position="relative">
        <Stack spacing={1} direction="row" alignItems="center">
          {record?.direction === 'Incoming' ?
            <CallReceived sx={{ color: '#448C3D', fontSize: '20px' }} />
            :
            <CallMade sx={{ color: '#2F6FCF', fontSize: '20px' }} />
          }
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {record?.answeredBy}
            {!!record?.answeredByStaffExtension && ` (ext ${record?.answeredByStaffExtension})`}
            {(!!record?.endTime && !record?.firstInteraction) &&
              ((record?.answeredBy || record?.answeredByStaffExtension) ? ' - Unanswered' : 'Unanswered')
            }
          </Typography>
        </Stack>
        {!!links.length &&
          <Box position="absolute" top={2} left="52%">
            <Typography sx={{ pl: '4px', fontSize: '12px', fontWeight: 700, lineHeight: '20px' }}>Links</Typography>
            <Box
              className="scrollbar-small"
              sx={{
                maxHeight: 210,
                overflowY: 'auto',
                direction: 'rtl',
                pl: '4px',
                '::-webkit-scrollbar': { width: '4px' },
                '::-webkit-scrollbar-track': { backgroundColor: '#E5E5E5' },
              }}
            >
              <Box sx={{ direction: 'ltr' }}>
                <Box
                  id={'formLinks' + callRecord.id}
                  sx={{
                    maxHeight: isExpanded ? undefined : (isLinksOverflown ? 100 : 120),
                    overflowY: 'hidden',
                  }}
                >
                  {links.map((link, index) => (
                    <Typography
                      key={link.link + index}
                      sx={{ ...linkStyle, lineHeight: '20px' }}
                      onClick={() => handleLink(link)}
                    >
                      {link.name}
                    </Typography>
                  ))}
                </Box>
                {(isLinksOverflown && !isExpanded) &&
                  <Typography
                    sx={{ ...linkStyle, lineHeight: '20px' }}
                    onClick={() => setIsExpanded(true)}
                  >
                    {'...'}
                  </Typography>
                }
              </Box>
            </Box>
          </Box>
        }
        <Stack spacing={1} direction="row" alignItems="center" zIndex={2}>
          {!!record?.transferToNumber &&
            <Tooltip title="Call has been transfered">
              <Stack spacing="4px" direction="row" alignItems="center">
                <Typography sx={{ fontWeight: 700, fontSize: '12px', color: '#1033A5' }}>
                  {record?.transferToName || record?.transferToNumber}
                </Typography>
                <ArrowRightAlt sx={{ color: '#1033A5', rotate: '180deg' }} />
              </Stack>
            </Tooltip>
          }
          {record?.flagEmailSent &&
            <Tooltip title="Call has been flagged">
              <Flag sx={{ color: '#E80B0B' }} />
            </Tooltip>
          }
          {(record?.completedFormIds && record?.completedFormIds !== '[]') &&
            <Tooltip title="Form(s) filled">
              <Chat sx={{ color: '#1033A5' }} />
            </Tooltip>
          }
          {!!record?.requeueCount &&
            <Tooltip title={`Call has been requeued ${record?.requeueCount} time(s)`}>
              <PhoneCallback sx={{ color: '#6A3686' }} />
            </Tooltip>
          }
          {(!!record?.endTime && !record?.firstInteraction) &&
            <Tooltip title="Call was unanswered">
              <CallMissed sx={{ color: '#9E0000' }} />
            </Tooltip>
          }
        </Stack>
      </Box>

      <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
        Caller:
        <Typography component="span" sx={linkTextStyle}>
          {combineNameAndNumber(record?.callerName, record?.callerNumber)}
        </Typography>
      </Typography>
      <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>
        Client:
        <Typography
          component="span"
          onClick={openAccount}
          sx={{
            ...linkTextStyle,
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          {record?.account?.name || 'N/A'}
        </Typography>
      </Typography>
      <Stack my={1} direction="row" alignItems="center">
        <Info label="Call SID" value={record?.callSid} />
        {record?.callSid &&
          <CopyButton text={record?.callSid || ''} />
        }
      </Stack>


      {!isExpanded &&
        <>
          <Box mb={1}><Info label="Start Time" value={formatDate(record?.startTime, DATE_TIME_FORMAT) || 'N/A'} /></Box>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between">
            <Box>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontWeight: 600, fontSize: '14px', mr: '4px' }}>{'Duration: '}</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '14px' }}>{secondsToHoursMinuteSecond(record?.durationInSeconds)}</Typography>
              </Box>
              {record?.callRecordingLink &&
                <Box display="flex" alignItems="center">
                  <RecordingPlayerSmall audioPlayer={audioPlayer} />
                </Box>
              }
            </Box>

            <Button
              size="small"
              variant="text"
              endIcon={<KeyboardArrowDown sx={{ fontSize: '24px !important' }} />}
              onClick={() => setIsExpanded(true)}
              sx={{
                color: '#1033A5',
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '14px',
              }}
            >
              See More
            </Button>
          </Box>
        </>
      }

      <Collapse in={isExpanded} mountOnEnter unmountOnExit>
        <Stack direction="row">
          <Box width="60%">
            <Box>
              <Info label="Client Categories" value={getAccountAttributes(record?.account).join(', ')} />
              <Info label="Number Caller Called" value={record?.direction === 'Incoming' ? record?.screenPop : record?.outboundCallNumberDialed} />
            </Box>
            {record?.notes &&
              <Box mt={1}>
                <Info label="Notes" value={record?.notes} />
              </Box>
            }
            <Box mt={1}>
              <Info label="Answered By" value={getAnsweredBy(record)} />
            </Box>
            {record?.callTakenBy &&
              <Box mt={1}>
                <Info label="Taken By" value={record.callTakenBy} />
                <Info label="Taken On" value={formatDate(record?.callTakenOn, DATE_TIME_FORMAT)} />
              </Box>
            }
            {record?.hangedUpBy &&
              <Box mt={1}>
                <Info label="Hanged Up By" value={record.hangedUpBy} />
              </Box>
            }

            <Box mt={2}>
              <Info label="Start Time" value={formatDate(record?.startTime, DATE_TIME_FORMAT)} />
              <Info label="Answer Time" value={formatDate(record?.firstInteraction, DATE_TIME_FORMAT)} />
              <Info label="End Time" value={formatDate(record?.endTime, DATE_TIME_FORMAT)} />
              <Info label="Duration" value={secondsToMinuteSecond(record?.durationInSeconds)} />
            </Box>
          </Box>
        </Stack>
        <Box mt={2}>
          <Stack spacing={5} direction="row">
            <Box sx={{ minWidth: 100 }}><Info label="Ring Time" value={secondsToMinuteSecond(record?.ringTimeInSeconds)} /></Box>
            <Box sx={{ minWidth: 100 }}><Info label="Talk Time" value={secondsToMinuteSecond(record?.talkTimeInSeconds)} /></Box>
            <Box sx={{ minWidth: 110 }}><Info label="Transfer Time" value={secondsToMinuteSecond(record?.transferTimeInSeconds)} /></Box>
            <Box sx={{ minWidth: 110 }}><Info label="Requeue Time" value={secondsToMinuteSecond(record?.requeueTime)} /></Box>
          </Stack>
          <Stack spacing={5} direction="row">
            <Box sx={{ minWidth: 100 }}><Info label="Hold Count" value={record?.holdCount || '0'} /></Box>
            <Box sx={{ minWidth: 100 }}><Info label="Hold Time" value={secondsToMinuteSecond(record?.holdTime)} /></Box>
            <Box sx={{ minWidth: 110 }}><Info label="Requeue Count" value={record?.requeueCount || '0'} /></Box>
          </Stack>
        </Box>

        {record?.transferToNumber &&
          <Box mt={2}>
            <Stack spacing={1} direction="row" alignItems="center">
              <ArrowRightAlt sx={{ color: '#1033A5', rotate: '180deg' }} />
              <Typography sx={{ fontWeight: 700, fontSize: '12px' }}>Transferred</Typography>
            </Stack>
            <Typography sx={{ mt: '4px', fontWeight: 400, fontSize: '12px' }}>
              Transferred To:
              <Typography component="span" sx={{ ...linkTextStyle, fontSize: '12px' }}>
                {combineNameAndNumber(record?.transferToName, record?.transferToNumber)}
              </Typography>
            </Typography>
          </Box>
        }

        {record?.flagEmailSent &&
          <Box mt={2}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Flag sx={{ color: '#E80B0B', fontSize: '18px' }} />
              <Typography sx={{ fontWeight: 700, fontSize: '12px' }}>Flagged</Typography>
            </Stack>
            <Typography sx={{ my: '4px', fontWeight: 600, fontSize: '12px' }}>Reason for call flagging</Typography>
            {record?.flagNotes?.startsWith('[')
              ? <Box sx={{ padding: '4px 8px', backgroundColor: '#E4ECFC', border: '1px solid #CBD8F1', borderRadius: '5px' }}>
                  <FlagNotes flagNotes={parseFlagNotes(record.flagNotes)} />
                </Box>
              : <FormField>{record.flagNotes}</FormField>
            }
          </Box>
        }

        {!!isLoadingForms &&
          [...new Array(isLoadingForms)].map((e, i) => (
            <Skeleton key={i} sx={{ mt: 2 }} variant="rectangular" width="80%" height={83} />
          ))
        }
        {formDatas.map(formData => (
          <Box mt={2} key={formData.id}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Chat sx={{ color: '#1033A5', fontSize: '18px' }} />
              <Typography sx={{ fontWeight: 700, fontSize: '12px' }}>Sent Form on {formData.date}</Typography>
            </Stack>
            <Box sx={{ minWidth: 100 }}><Info label="To" value={formData.sentTo.join(', ')} /></Box>
            <Box sx={{ minWidth: 100 }}><Info label="Subject" value={formData.subject} /></Box>
            <Button
              size="small"
              variant="text"
              endIcon={
                <KeyboardArrowDown
                  sx={{
                    fontSize: '18px !important',
                    rotate: isFormExpanded[formData.id] ? '-180deg' : '0deg',
                    transition: 'rotate 0.3s',
                  }}
                />
              }
              onClick={() => setIsFormExpanded({ ...isFormExpanded, [formData.id]: !isFormExpanded[formData.id] })}
              sx={{
                color: '#1033A5',
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '12px',
                ml: '-4px',
              }}
            >
              {isFormExpanded[formData.id] ? 'Hide Details' : 'Show Details'}
            </Button>
            <Collapse in={isFormExpanded[formData.id]}>
              {Object.entries(formData.details).map(([label, value], index) => (
                <Box key={index} mb={1}>
                  <Typography sx={{ my: '4px', fontWeight: 600, fontSize: '12px' }}>{label}</Typography>
                  <FormField>{value || 'N/A'}</FormField>
                </Box>
              ))}
            </Collapse>
          </Box>
        ))}

        {!!interactions?.length &&
          <Box mt={2} width={450} border="1px solid #CBD8F1" borderRadius="5px">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Stack ml={1} spacing={1} direction="row" alignItems="center">
                <TouchApp sx={{ color: '#1033A5', fontSize: '18px', pb: '4px' }} />
                <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>Interactions</Typography>
              </Stack>

              <Button
                size="small"
                variant="text"
                endIcon={
                  <KeyboardArrowDown
                    sx={{
                      fontSize: '18px !important',
                      rotate: isInteractionsExpanded ? '-180deg' : '0deg',
                      transition: 'rotate 0.3s',
                    }}
                  />
                }
                onClick={() => setIsInteractionsExpanded(!isInteractionsExpanded)}
                sx={{
                  color: '#1033A5',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '12px',
                  pl: 2,
                  width: 94,
                }}
              >
                {isInteractionsExpanded ? 'Collapse' : 'Expand'}
              </Button>
            </Box>
            <Collapse in={isInteractionsExpanded}>
              <Table size="small" sx={{ width: '100%', mt: 2 }}>
                <TableBody>
                  {interactions.map(data => (
                    <TableRow key={data.StartTime} sx={{ '&:nth-of-type(even)': { backgroundColor: '#E4ECFC' } }}>
                      <TableCell sx={{ borderBottom: 'none', width: '20%' }}>
                        <Typography sx={{ fontWeight: 400, fontSize: '12px' }}>{formatDate(data.StartTime, 'HH:mm:ss')}</Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', width: '40%', }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{startCase(data.Type)}</Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', width: '40%' }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{data.Description}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </Box>
        }

        {calleeRecordingLinks.map(recordingLink => (
          <>
            <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#1033A5', mt: 3, mb: 1 }}>{`Call Recording (Receptionist & Callee ${recordingLink.number})`}</Typography>
            <Box width="calc(100% - 120px)">
              <AudioRecordingPlayer recordingLink={recordingLink.link} isGlobal={isGlobal} isViewingGlobalCallHistory={isViewingGlobalCallHistory} />
            </Box>
          </>
        ))}
        {callRecord?.callRecordingLink &&
          <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#1033A5', mt: 3, mb: 1 }}>{'Call Recording (Caller & Receptionist)'}</Typography>
        }
        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Box width="calc(100% - 120px)">
            {callRecord?.callRecordingLink && <RecordingPlayer audioPlayer={audioPlayer} />}
          </Box>

          <Button
            size="small"
            variant="text"
            endIcon={<KeyboardArrowUp sx={{ fontSize: '24px !important' }} />}
            onClick={collapseCard}
            sx={{
              color: '#1033A5',
              textTransform: 'none',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            See Less
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
}

export default HistoryCard;