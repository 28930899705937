import React from "react";
import { Box, Typography } from "@mui/material";
import SideInfo from "./components/SideInfo";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

const About = ({ showCustomHours }: { showCustomHours: () => void }) => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)

  return (
    <Box display="flex" height="calc(100% - 36px)">
      <Box
        sx={{
          mt: 3, pr: 2, mr: 1,
          width: '320px',
          height: 'calc(100% - 24px)',
          overflowY: 'auto',
        }}
      >
        <SideInfo showCustomHours={showCustomHours} />
      </Box>
      <Box
        sx={{
          width: 'calc(100% - 8px - 320px)',
          mt: 3, mb: 1, pr: 1,
          height: 'calc(100% - 24px - 8px)',
          backgroundColor: '#F5F8FD',
        }}
      >
        <Box
          sx={{
            py: 2, px: 3,
            height: 'calc(100% - 32px)',
            backgroundColor: '#F5F8FD',
            overflow: 'auto',
          }}
        >
          {(!account.moreInformation && !account.clientTopGoal) &&
            <Box display="flex" alignItems="center" justifyContent="center" height="90%">
              <Typography style={{ fontSize: '24px', fontWeight: 600 }}>
                No Information Provided
              </Typography>
            </Box>
          }

          {account.moreInformation &&
            <Box mb={3}>
              <Typography style={{ fontWeight: 500, fontSize: '24px' }}>More information</Typography>
              <Typography style={{ fontSize: '14px' }} component="div">
                <div dangerouslySetInnerHTML={{ __html: account.moreInformation }}></div>
              </Typography>
            </Box>
          }

          {account.clientTopGoal &&
            <Box mb={3}>
              <Typography style={{ fontWeight: 500, fontSize: '24px' }}>Client's Top Goal</Typography>
              <Typography style={{ fontSize: '14px' }} component="div">
                <div dangerouslySetInnerHTML={{ __html: account.clientTopGoal }}></div>
              </Typography>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default About;
