import React, { useMemo } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { getAccountAttributes } from "utils/accounts";

const AccountName = () => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)

  const additionalInfo = useMemo(() => {
    const attributes = getAccountAttributes(account)

    if (attributes.length) {
      return `(${attributes.join(', ')})`
    }
    return ''
  }, [account])

  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '18px',
        color: '#3D3D3D',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      <Typography sx={{ fontWeight: 600, fontSize: '24px', mr: 1, color: '#000' }} component="span">
        {account.name}
      </Typography>
      <Tooltip title={additionalInfo}>
        <span>
          {additionalInfo}
        </span>
      </Tooltip>
    </Typography>
  );
};

export default AccountName;
