import React, { FC, useMemo } from "react";
import { Box, IconButton, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import { Email, MergeType, Phone, PriorityHigh, Voicemail } from "@mui/icons-material";
import { Contact, getContactName, getContactUnavailability, getTransferNumbers, getTypeOfCalls } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import CopyButton from "components/buttons/CopyButton";
import { formatDate } from "utils/utils";


interface IProps {
  search: string,
  contact: Contact,
  isSelected: boolean,
  isGloballySelected: boolean,
  onSelect?: (contact: Contact) => void,
}

const TAKES_CALLS_COLORS: Record<string, string> = {
  Yes: '#448C3D',
  No: '#E80B0B',
  'Emergency Calls Only': '#FFB200',
  Default: '#E80B0B',
}

// Static contact without option to click on numbers
const ContactListItemStatic: FC<IProps> = ({ contact, isSelected, isGloballySelected, onSelect, search }) => {
  const transferNumbers = useMemo(() => {
    if (!contact) return []

    return getTransferNumbers(contact)
  }, [contact])

  const typesOfCalls: string = useMemo(() => {
    return getTypeOfCalls(contact)
  }, [contact])

  const noBubbleUp = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const currentUnavailability = getContactUnavailability(contact, true)
  const unavailability = getContactUnavailability(contact, false)
  const hideNumbers = !contact.connectOnly && contact.doYouTakeCalls !== 'Yes' && contact.doYouTakeCalls !== 'Emergency Calls Only'
  return (
    <ListItemButton
      id={contact.id}
      key={contact.id}
      onClick={() => onSelect && onSelect(contact)}
      sx={{
        display: 'block',
        px: 2, py: 1,
        cursor: 'pointer',
        border: '2px solid',
        borderBottom: 'none',
        borderColor: isSelected ? '#A5B5E3' : 'transparent',
        borderTopColor: '#A5B5E3',
        backgroundColor: isGloballySelected ? 'mark'
          : isSelected ? '#E4ECFC !important'
          : '#F5F8FD',
        '&:hover': {
          backgroundColor: '#E4ECFC !important',
        },
        '&:last-of-type': {
          border: '2px solid',
          borderColor: isSelected ? '#A5B5E3' : 'transparent',
          borderTopColor: '#A5B5E3',
          borderBottomColor: '#A5B5E3',
        },
        transition: 'border 0.5s',
      }}
    >
      <Box display="flex" alignItems="center">
        <Box width="100%">
          <Stack direction="row" alignItems="center">
            <Phone
              sx={{
                color: TAKES_CALLS_COLORS[contact.doYouTakeCalls || 'Default'],
                fontSize: '18px',
              }}
            />
            {contact.doYouTakeCalls === 'Emergency Calls Only' &&
              <PriorityHigh sx={{ mx: '-6px', color: TAKES_CALLS_COLORS['Emergency Calls Only'], fontSize: '18px' }} />
            }
            <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#1033A5', mx: 1 }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={getContactName(contact)}
              />
            </Typography>
            {contact.title &&
              <Typography style={{ fontWeight: 600, fontSize: '13px' }}>
                <Highlighter
                  searchWords={[search.toLowerCase()]}
                  autoEscape={true}
                  textToHighlight={contact.title}
                />
              </Typography>
            }
          </Stack>

          {contact.displayAsAlert &&
            <>
              {(contact.doYouTakeCalls === 'Yes' &&
                (contact.currentStatusNote || contact.doNotConnect)
              ) &&
                <Box
                  sx={{
                    my: '4px', p: '12px',
                    width: 'calc(100% - 24px - 2px)',
                    border: '1px solid rgba(68, 140, 61, 0.6)',
                    borderRadius: '5px',
                    backgroundColor: 'rgba(68, 140, 61, 0.2)'
                  }}
                >
                  <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                    {formatDate(contact.currentStatusAppliedDate, 'MM/DD/YYYY h:mm A')}
                    {' - '}
                    {contact.currentStatusExpirationDate ? formatDate(contact.currentStatusExpirationDate, 'MM/DD/YYYY h:mm A') : 'UFN'}
                  </Typography>
                  <Typography style={{ fontSize: '12px' }}>Taking Calls</Typography>
                  {contact.doNotConnect &&
                    <Typography style={{ fontSize: '12px' }}>
                      <Highlighter
                        searchWords={[search.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={`Do not connect: ${contact.doNotConnect.split(';').join(',')}`}
                      />
                    </Typography>
                  }
                  {contact.currentStatusNote &&
                    <Typography style={{ fontSize: '12px' }}>
                      <Highlighter
                        searchWords={[search.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={`Note: ${contact.currentStatusNote}`}
                      />

                    </Typography>
                  }
                </Box>
              }

              {(contact.doYouTakeCalls !== 'Yes' &&
                (currentUnavailability || contact.connectOnly || contact.currentStatusNote)
              ) &&
                <Box
                  sx={{
                    my: '4px', p: '12px',
                    width: 'calc(100% - 24px - 2px)',
                    border: '1px solid rgba(232, 11, 11, 0.6)',
                    borderRadius: '5px',
                    backgroundColor: 'rgba(232, 11, 11, 0.2)'
                  }}
                >
                  {contact.currentStatusAppliedDate &&
                    <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                      {formatDate(contact.currentStatusAppliedDate, 'MM/DD/YYYY h:mm A')}
                      {' - '}
                      {contact.currentStatusExpirationDate ? formatDate(contact.currentStatusExpirationDate, 'MM/DD/YYYY h:mm A') : 'UFN'}
                    </Typography>
                  }
                  <Typography style={{ fontSize: '12px' }}>
                    <Highlighter
                      searchWords={[search.toLowerCase()]}
                      autoEscape={true}
                      textToHighlight={currentUnavailability}
                    />
                  </Typography>
                  {contact.connectOnly &&
                    <Typography style={{ fontSize: '12px' }}>
                      <Highlighter
                        searchWords={[search.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={`Connect only: ${contact.connectOnly.split(';').join(',')}`}
                      />

                    </Typography>
                  }
                  {contact.currentStatusNote &&
                    <Typography style={{ fontSize: '12px' }}>
                      <Highlighter
                        searchWords={[search.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={`Note: ${contact.currentStatusNote}`}
                      />
                    </Typography>
                  }
                </Box>
              }
            </>
          }

          {unavailability &&
            <Box
              sx={{
                my: '4px', p: '12px',
                width: 'calc(100% - 24px - 2px)',
                border: '1px solid rgba(143, 96, 14, 0.8)',
                borderRadius: '5px',
                backgroundColor: 'rgba(143, 96, 14, 0.5)'
              }}
            >
              <Typography sx={{ fontSize: '13px' }}>
                <Highlighter
                  searchWords={[search.toLowerCase()]}
                  autoEscape={true}
                  textToHighlight={unavailability}
                />
              </Typography>
            </Box>
          }


          <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
            Takes Calls:&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={contact.doYouTakeCalls || 'No'}
              />
            </span>
          </Typography>
          <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
            Type:&nbsp;
            <span style={{ fontWeight: 'normal' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={typesOfCalls}
              />
            </span>
          </Typography>
          {contact.daysAndTimesYouTakeLiveCalls &&
            <Typography sx={{ fontSize: '13px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={contact.daysAndTimesYouTakeLiveCalls}
              />
            </Typography>
          }
          {!hideNumbers && transferNumbers.map(transferNumber => (
            <Stack direction="row" alignItems="center" key={transferNumber.value} flexWrap="wrap">
              <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 600 }}>
                {transferNumber.label}:&nbsp;
              </Typography>
              <Stack direction="row" alignItems="center" key={transferNumber.value} minHeight={21}>
                <Typography
                  sx={{ fontSize: '13px', whiteSpace: 'nowrap' }}
                  onMouseDown={noBubbleUp}
                >
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={transferNumber.value}
                  />
                </Typography>
                <CopyButton text={transferNumber.value} />
                {transferNumber.type === 'Both' &&
                  <Tooltip arrow placement="top" title="Blind Transfer">
                    <span>
                      <IconButton sx={{ p: 0, ml: '4px', color: '#D79F2B' }} disabled>
                        <MergeType sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              </Stack>
            </Stack>
          ))}
          {contact.assignedVoicemail &&
            <Stack direction="row" alignItems="center" minHeight={21}>
              <Voicemail sx={{ color: '#F2B648', fontSize: '18px', mr: 1 }} />
              <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
                VM:&nbsp;
                <span onMouseDown={noBubbleUp}>
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={contact.assignedVoicemail}
                  />
                </span>
              </Typography>
              <CopyButton text={contact.assignedVoicemail} />
              <Tooltip arrow placement="top" title="Blind Transfer">
                <span>
                  <IconButton
                    sx={{ p: 0, ml: '4px', color: '#D79F2B' }}
                    disabled
                  >
                    <MergeType sx={{ fontSize: '20px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          }
          {(contact.email && contact.okToGiveOutEmailAddress !== 'No') &&
            <Stack direction="row" alignItems="center">
              <Email sx={{ color: '#2F6FCF', fontSize: '18px', mr: '1px' }} />
              <Typography sx={{ fontSize: '13px' }}>
                :&nbsp;
                <span
                  onMouseDown={noBubbleUp}
                  style={{ color: '#1033A5', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  <Highlighter
                    searchWords={[search.toLowerCase()]}
                    autoEscape={true}
                    textToHighlight={contact.email}
                  />
                </span>
              </Typography>
              <CopyButton text={contact.email} />
            </Stack>
          }
        </Box>
      </Box>
    </ListItemButton>
  )
}

export default ContactListItemStatic;
