import React, { useEffect, useState } from "react";
import { Box, Button, Paper, styled } from "@mui/material";
import AccountName from "./components/AccountName";
import Account from "./Account";
import About from "./About";
import { ACCOUNT_PANEL_STATUS } from "utils/accounts";
import Status from "./Status";
import TaskButtons from "./components/TaskButtons";
import Contacts from "./contacts/Contacts";
import AccountCallHistory from "./callHistory/AccountCallHistory";
import Tasks from "./tasks/Tasks";
import { useDispatch } from "react-redux";
import { setSelectedTask } from "reducers/slices/accountPanelReducer";
import TaskCallerHistory from "./tasks/TaskCallerHistory";
import { useAppSelector } from "reducers/hooks";

enum Tab {
  Account = 'Account',
  About = 'About',
  CallHistory = 'Call History',
}
const TABS = [
  Tab.Account,
  Tab.About,
  Tab.CallHistory,
]

const TabButton = styled(Button)(() => ({
  width: 102,
  marginLeft: -8,
  '&:first-of-type': { marginLeft: 0 },
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',

  color: '#213299',
  fontSize: '12px',
  fontWeight: 600,
}))

const SelectedTabButton = styled(TabButton)(() => ({
  zIndex: 7,
  color: '#000000',
  '&:hover': {
    color: '#000000',
    backgroundColor: '#FFF',
  },
}))

const AccountLayout = () => {
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0])
  const [faqSearchOverride, setFaqSearchOverride] = useState<string>('')
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const account = useAppSelector(state => state.accountPanel.activeAccount)
  const status = useAppSelector(state => state.accountPanel.status)
  const tasks = useAppSelector(state => state.accountPanel.tasks)
  const selectedTask = useAppSelector(state => state.accountPanel.selectedTask)
  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedTab(TABS[0])
  }, [account?.id])

  const onSelectTab = (tabName: string) => {
    setSelectedTab(tabName)
    dispatch(setSelectedTask(''))
  }

  const onShowCustomHoursOperation = () => {
    if (selectedTab !== Tab.Account) onSelectTab(Tab.Account)

    setFaqSearchOverride('Hours/Days of Operation')
  }

  const activeTask = tasks.find(e => e.name === selectedTask)
  return (
    <Box height={`calc(100% ${callEnabled ? '- 118px' : '- 34px'} - 8px - 48px - 16px - 38px)`}>
      <Box width="100%" display="flex">
        <Box pr={2}>
          {TABS.map((tab, i) => (
            (!activeTask && selectedTab === tab) ?
              <SelectedTabButton key={tab}>{tab}</SelectedTabButton>
              :
              <TabButton
                key={tab}
                onClick={() => onSelectTab(tab)}
                sx={{ zIndex: 5 - i }}
              >
                {tab}
              </TabButton>
          ))}
        </Box>
        <TaskButtons />
      </Box>
      <Box // Divider so it looks good in between tabs and content
        sx={{
          width: 102 * TABS.length,
          height: 16,
          mb: '-16px',
          backgroundColor: 'white',
          position: 'relative',
          zIndex: 10,
        }}
      />
      <Paper
        sx={{
          display: 'flex',
          zIndex: 6,
          position: 'relative',
          borderTopLeftRadius: 0,
          pt: 2,
          height: 'calc(100% - 32px - 16px)',
        }}
      >
        <Box display={activeTask ? 'block' : 'none'} width="calc(100% - 48px - 360px)" px={3} height="100%">
          <Tasks />
        </Box>
        <Box display={activeTask ? 'none' : 'block'} width="calc(100% - 48px - 360px)" px={3} height="100%">
          <>
            {status === ACCOUNT_PANEL_STATUS.LOADED ?
              <>
                <AccountName />
                {selectedTab === Tab.Account &&
                  <Account
                    showCustomHours={onShowCustomHoursOperation}
                    searchOverride={faqSearchOverride}
                    clearSearchOverride={() => setFaqSearchOverride('')}
                  />
                }
                {selectedTab === Tab.About && <About showCustomHours={onShowCustomHoursOperation} />}
                {selectedTab === Tab.CallHistory && <AccountCallHistory />}
              </>
              :
              <Status />
            }
          </>
        </Box>


        <Box width="360px">
          <Paper
            sx={{
              mt: -2,
              height: 'calc(100% + 16px)',
              borderRadius: '5px',
            }}
            elevation={5}
          >
            <Box display={activeTask ? 'block' : 'none'} width="100%" height="100%">
              <TaskCallerHistory />
            </Box>
            <Box display={activeTask ? 'none' : 'block'} width="100%" height="100%">
              <Contacts />
            </Box>
          </Paper>
        </Box>

      </Paper>
    </Box>
  );
};

export default AccountLayout;
