import moment from "moment-timezone"

export const nameToInitial = (name: string | null) => {
  if (!name) return ''
  const splitName = name.split(' ')
  let initial = splitName[0][0]
  if (splitName.length > 1) {
    initial += splitName[splitName.length - 1][0]
  }

  return initial
}

export const formatDate = (dateString?: string | null, format?: string) => {
  if (!dateString) return dateString

  return moment(dateString).format(format || 'MM/DD/YYYY')
}

export const secondsToHoursMinuteSecond = (seconds?: number | null) => {
  if (!seconds) return '00:00:00'

  const roundedSeconds = Math.round(seconds)
  const hours = Math.floor(roundedSeconds / (60 * 60)).toString().padStart(2, '0')
  const minutes = Math.floor((roundedSeconds % (60 * 60)) / 60).toString().padStart(2, '0')
  const remainingSeconds = Math.floor((roundedSeconds % (60 * 60)) % 60).toString().padStart(2, '0')

  return `${hours}:${minutes}:${remainingSeconds}`
}

export const secondsToMinuteSecond = (seconds?: number | null) => {
  if (!seconds) return '0:00'

  const roundedSeconds = Math.round(seconds)
  const minutes = Math.floor(roundedSeconds / 60)

  return `${minutes}:${Math.floor(roundedSeconds % 60).toString().padStart(2, '0')}`
}

export function removeLeadingPlus (num?: string) {
  if (!num) return num

  const trimmedNum = num.trim()
  return trimmedNum[0] === '+' ? trimmedNum.replace('+', '') : trimmedNum
}

// https://stackoverflow.com/a/274094
function regexIndexOf(string: string, regex: RegExp, startpos: number) {
  var indexOf = string.substring(startpos || 0).search(regex)
  return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf
}

export const highlightHtml = (htmlString: string, searchString: string) => {
  const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

  let currentIndex = -1
  let foundIndex = regexIndexOf(htmlString, new RegExp('(?<!<[^>]*)' + regEscape(searchString), 'ig'), 0)
  const foundIndexes = []
  while (foundIndex > currentIndex) {
    foundIndexes.push(foundIndex)
    currentIndex = foundIndex
    foundIndex = regexIndexOf(htmlString, new RegExp('(?<!<[^>]*)' + regEscape(searchString), 'ig'), currentIndex)
  }

  const textLength = searchString.length
  let highlightedHtml = htmlString
  // Work from the last so the leading indexes does not get messed up
  foundIndexes.reverse().forEach(index => {
    highlightedHtml = highlightedHtml.slice(0, index) +
      `<mark>${highlightedHtml.slice(index, index + textLength)}</mark>` +
      highlightedHtml.slice(index + textLength)
  })

  return highlightedHtml
}

export interface AppLog {
  timestamp: number,
  log: string,
}

export const logger = (...args: any[]) => {
  const values: any[] = []
  args.forEach(argument => {
    if (typeof argument === 'object') {
      values.push(JSON.stringify(argument))
      return
    }
    values.push(argument)
  })

  const message = `${new Date().toISOString()}: ${values.join(' ')}`
  console.log(message)

  let appLogs: AppLog[] = []

  try {
    const savedLogs = localStorage.getItem(LOG_STORAGE)
    if (savedLogs) {
      appLogs = JSON.parse(savedLogs)
    }
  } catch (err) {
    console.log(err)
  }

  const now = Date.now()
  // Filter last 30 mins appLogs
  appLogs = appLogs.filter(e => e.timestamp + 15 * 60 * 1000 > now)
  appLogs.push({ timestamp: now, log: message })
  localStorage.setItem(LOG_STORAGE, JSON.stringify(appLogs))
}

export enum ViewRoute {
  RECEPTIONIST_VIEW = '/receptionist',
  ACTIVITY_VIEW = '/activity',
}

export const stringContainsHtmlTags = (str: string) => {
  const regex = new RegExp(/<[^>]*>/g)
  return regex.test(str)
}

export const LOG_STORAGE = 'LOG_STORAGE'
