import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallNoteState, CallSlot, CallState } from "utils/calls";

interface OtherUserCalls {
  [userId: string]: string[], // [userId]: [conferenceName]
}

interface OtherUserCallPayload {
  userId: string,
  conferenceName: string,
}

interface ClaimSlotPayload {
  conferenceName: string,
  isOutbound?: boolean,
  autoSelect?: boolean,
  isPriority?: boolean,
}

interface CallSlotMap {
  [slotId: string]: CallSlot
}

export const OUTBOUND_SLOT = 'outbound-only-slot'

export interface callSlotState {
  // Receptionist view
  callSlots: CallSlotMap,
  assignedCalls: { [slotName: string]: string | undefined },
  selectedSlot: string | null,
  selectedCall: CallState | undefined,
  activeCall: CallState | undefined,
  unassignedCalls: string[],
  priorityUnassignedCalls: string[], // For unassigned user calls
  outboundUnassignedCall: string | null,
  openCallNotes: CallNoteState[],

  // Activity view
  unansweredCalls: string[],
  otherUserCalls: OtherUserCalls,
  listeningToOperatorId: string | null,
}

const initialState: callSlotState = {
  callSlots: {},
  assignedCalls: {},
  selectedSlot: null,
  selectedCall: undefined,
  activeCall: undefined,
  unassignedCalls: [],
  priorityUnassignedCalls: [],
  outboundUnassignedCall: null,
  openCallNotes: [],

  unansweredCalls: [],
  otherUserCalls: {},
  listeningToOperatorId: null,
};

export const callSlotSlice = createSlice({
  name: "callSlot",
  initialState,
  reducers: {
    registerSlot: (state, action: PayloadAction<CallSlot>) => {
      const callSlot = action.payload
      if (state.callSlots[callSlot.name]) {
        state.callSlots[callSlot.name].disabled = false
      } else {
        state.callSlots[callSlot.name] = callSlot
      }
    },
    deregisterSlot: (state, action: PayloadAction<CallSlot>) => {
      const callSlot = action.payload
      if (state.callSlots[callSlot.name]) {
        state.callSlots[callSlot.name].disabled = true
      }
    },
    selectSlot: (state, action: PayloadAction<string | null>) => {
      state.selectedSlot = action.payload
    },
    claimSlot: (state, action: PayloadAction<ClaimSlotPayload>) => {
      const { conferenceName, isPriority, isOutbound, autoSelect } = action.payload

      const callSlots = Object.values(state.callSlots)
      const alreadyClaimed = callSlots.find(e => e.claimedBy === conferenceName)
      if (alreadyClaimed) return

      const now = Date.now()
      const claimedSlot = callSlots.find(e =>
        !e.claimedBy &&
        (autoSelect || !e.disabled) && // Autoselect override disabled state
        now > (e.cooldownPeriod || 0)
      )
      if (claimedSlot) {
        state.callSlots[claimedSlot.name].claimedBy = conferenceName

        state.unassignedCalls = state.unassignedCalls.filter(e => e !== conferenceName)
        state.priorityUnassignedCalls = state.priorityUnassignedCalls.filter(e => e !== conferenceName)
        state.assignedCalls[claimedSlot.name] = conferenceName

        if (isOutbound) {
          state.assignedCalls[OUTBOUND_SLOT] = undefined
        }

        if (autoSelect || isOutbound) {
          state.selectedSlot = claimedSlot.name
        }
      } else {
        if (isOutbound) {
          state.outboundUnassignedCall = conferenceName
          state.selectedSlot = OUTBOUND_SLOT
          state.assignedCalls[OUTBOUND_SLOT] = conferenceName
        } else {
          if (isPriority) {
            state.priorityUnassignedCalls = state.priorityUnassignedCalls.concat(conferenceName)
          } else {
            state.unassignedCalls = state.unassignedCalls.concat(conferenceName)
          }
        }
      }
    },
    // Never use this directly, use the thunk instead.
    unclaimCallSlot: (state, action: PayloadAction<string>) => {
      const conferenceName = action.payload

      const callSlots = Object.values(state.callSlots)
      // Free the call from the call slot and remove it from assigned calls
      const claimedSlots = callSlots.filter(e => e.claimedBy === conferenceName)
      claimedSlots.forEach(claimedSlot => {
        state.callSlots[claimedSlot.name].cooldownPeriod = Date.now() + 500
        state.callSlots[claimedSlot.name].claimedBy = undefined

        state.assignedCalls[claimedSlot.name] = undefined

        if (state.selectedSlot === claimedSlot.name) {
          state.selectedSlot = null
        }

        if (state.selectedCall?.conferenceName === conferenceName) {
          state.selectedCall = undefined
        }

        if (state.activeCall?.conferenceName === conferenceName) {
          state.activeCall = undefined
        }
      })

      // Call is not in any call slot, so just remove it from both unassigned and assigned calls
      const isUnassignedCall = state.unassignedCalls.includes(conferenceName) || state.priorityUnassignedCalls.includes(conferenceName)
      if (isUnassignedCall) {
        state.unassignedCalls = state.unassignedCalls.filter(e => e !== conferenceName)
        state.priorityUnassignedCalls = state.priorityUnassignedCalls.filter(e => e !== conferenceName)

        const assignedCalls = state.assignedCalls
        if (Object.values(assignedCalls).includes(conferenceName)) {
          const key = Object.keys(assignedCalls).find(key => assignedCalls[key] === conferenceName)
          if (key) {
            state.assignedCalls[key] = undefined
          }
        }
      }

    },
    setOtherUserCalls: (state, action: PayloadAction<any>) => {
      const activeCalls = action.payload
      const otherUserCalls: OtherUserCalls = {}

      activeCalls.forEach((call: any) => {
        if (!otherUserCalls[call.operatorId]) {
          otherUserCalls[call.operatorId] = []
        }

        otherUserCalls[call.operatorId].push(call.conferenceName)
      })

      return {
        ...state,
        otherUserCalls,
      }
    },
    addOtherUserCall: (state, action: PayloadAction<OtherUserCallPayload>) => {
      const { userId, conferenceName } = action.payload
      const calls = state.otherUserCalls[userId] || []
      if (!calls.includes(conferenceName)) {
        state.otherUserCalls[userId] = calls.concat(conferenceName)
      }
    },
    // removeOtherUserCall: (state, action: PayloadAction<OtherUserCallPayload>) => {
    //   const { userId, conferenceName } = action.payload
    //   const calls = (state.otherUserCalls[userId] || []).slice()

    //   return {
    //     ...state,
    //     otherUserCalls: {
    //       ...state.otherUserCalls,
    //       [userId]: calls.filter(e => e !== conferenceName),
    //     }
    //   }
    // },
    // Ideally we should use the above
    removeOtherUserCall: (state, action: PayloadAction<string>) => {
      const conferenceName = action.payload
      const otherUserCalls: OtherUserCalls = {}
      Object.keys(state.otherUserCalls).forEach(userId => {
        otherUserCalls[userId] = (state.otherUserCalls[userId] || []).filter(e => e !== conferenceName)
      })

      return {
        ...state,
        otherUserCalls,
      }
    },
    addUnansweredCall: (state, action: PayloadAction<string | string[]>) => {
      let newCalls = action.payload
      if (!Array.isArray(newCalls)) {
        newCalls = [newCalls]
      }

      state.unansweredCalls = Array.from(new Set(state.unansweredCalls.concat(newCalls)))
    },
    removeUnansweredCall: (state, action: PayloadAction<string>) => {
      state.unansweredCalls = state.unansweredCalls.filter(e => e !== action.payload)
    },
    setListeningToOperatorId: (state, action: PayloadAction<string | null>) => {
      state.listeningToOperatorId = action.payload
    },
    openCallNote: (state, action: PayloadAction<CallNoteState>) => {
      const openCallNotes = state.openCallNotes.slice()
      if (openCallNotes.find(e => e.conferenceName === action.payload.conferenceName)) {
        return {
          ...state,
        }
      }

      openCallNotes.push(action.payload)
      return {
        ...state,
        openCallNotes,
      }
    },
    closeCallNote: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        openCallNotes: state.openCallNotes.filter(e => e.conferenceName !== action.payload),
      }
    },
    // Clear up slots cooldown to trigger an update
    clearSlotsCooldown: (state) => {
      const now = Date.now()
      const callSlots = Object.values(state.callSlots)

      callSlots.forEach(callSlot => {
        if (callSlot.cooldownPeriod && now > callSlot.cooldownPeriod) {
          state.callSlots[callSlot.name].cooldownPeriod = 0
        }
      })
    },
    setSelectedCall: (state, action: PayloadAction<CallState | undefined>) => {
      state.selectedCall = action.payload
    },
    setActiveCall: (state, action: PayloadAction<CallState | undefined>) => {
      state.activeCall = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  registerSlot, deregisterSlot, claimSlot, selectSlot, unclaimCallSlot,
  setOtherUserCalls, addOtherUserCall, removeOtherUserCall,
  addUnansweredCall, removeUnansweredCall, setListeningToOperatorId,
  openCallNote, closeCallNote, clearSlotsCooldown, setSelectedCall, setActiveCall,
} = callSlotSlice.actions;

export default callSlotSlice.reducer;
